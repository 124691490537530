define('gmc/adapters/gmc-device', ['exports', 'gmc-liveops/adapters/gmc-device'], function (exports, _gmcDevice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gmcDevice.default;
    }
  });
});