define('gmc/components/sidebar-meta-edit', ['exports', 'ui-core/components/sidebar-meta-edit'], function (exports, _sidebarMetaEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sidebarMetaEdit.default;
    }
  });
});