define('gmc/components/nav-pagination-cursor-link', ['exports', 'ui-core/components/nav-pagination-cursor-link'], function (exports, _navPaginationCursorLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _navPaginationCursorLink.default;
    }
  });
});