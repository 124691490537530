define('gmc/helpers/i18n', ['exports', 'ui-core/helpers/i18n'], function (exports, _i18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _i18n.default;
    }
  });
  Object.defineProperty(exports, 'i18n', {
    enumerable: true,
    get: function () {
      return _i18n.i18n;
    }
  });
});