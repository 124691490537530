define('gmc/services/response-type-service', ['exports', 'gmc-game-settings/services/response-type-service'], function (exports, _responseTypeService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _responseTypeService.default;
    }
  });
});