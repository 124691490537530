define('gmc/helpers/style-delimited-str', ['exports', 'ui-core/helpers/style-delimited-str'], function (exports, _styleDelimitedStr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _styleDelimitedStr.default;
    }
  });
  Object.defineProperty(exports, 'styleDelimitedStr', {
    enumerable: true,
    get: function () {
      return _styleDelimitedStr.styleDelimitedStr;
    }
  });
});