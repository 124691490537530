define('gmc/routes/dashboard', ['exports', 'gmc/routes/base', 'gmc/mixins/game-code', 'gmc/config/environment'], function (exports, _base, _gameCode, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend(_gameCode.default, {

    // Defaults

    gmc: Ember.inject.service(),

    session: Ember.inject.service(),

    // Single line CP

    gameCode: Ember.computed.alias('gmc.session.game.code'),

    // Multiline CP

    // Lifecycle hooks
    beforeModel: function beforeModel(transition) {
      if (!this.session.isAuthenticated) {
        // get(this, 'gmc').clearSession();
        this.transitionTo('login');
        return;
      }

      if (transition.queryParams.close) {
        try {
          window.close();
        } catch (error) {
          console.log(error);
        }
      }

      var countDownDate = new Date("Dec 11, 2024 10:30:00").getTime();
      // Update the count down every 1 second
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
        var seconds = Math.floor(distance % (1000 * 60) / 1000);

        // Display the result in the element with id="demo"
        document.getElementById("nav_countdown").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          document.getElementById("nav_countdown").innerHTML = 'Any moment now...';
        }
      }, 1000);
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.transitionTo('dashboard', Ember.get(this, 'gmc.session.game.code'));
    }

    // Private

  });
});