define('gmc/helpers/format-str', ['exports', 'ui-core/helpers/format-str'], function (exports, _formatStr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatStr.default;
    }
  });
  Object.defineProperty(exports, 'formatStr', {
    enumerable: true,
    get: function () {
      return _formatStr.formatStr;
    }
  });
});