define('gmc/components/confirm-dangerous-dialog', ['exports', 'ui-core/components/confirm-dangerous-dialog'], function (exports, _confirmDangerousDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _confirmDangerousDialog.default;
    }
  });
});