define('gmc/transforms/object', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    serialize: function serialize(value) {
      if (value) {
        return Object.keys(value).length ? value : {};
      }
    },
    deserialize: function deserialize(value) {
      if (value) {
        return Object.keys(value).length ? value : {};
      }
    }
  });
});