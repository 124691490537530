define('gmc/controllers/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    // Defaults

    session: Ember.inject.service()

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    // Private

  });
});