define('gmc/components/file-preview', ['exports', 'gmc-cross-promos/components/file-preview'], function (exports, _filePreview) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _filePreview.default;
    }
  });
});