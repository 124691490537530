define('gmc/routes/component-views/detail', ['exports', 'gmc-game-settings/routes/component-views/detail'], function (exports, _detail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _detail.default;
    }
  });
});