define('gmc/controllers/dashboard', ['exports', 'gmc/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		// Defaults

		gmc: Ember.inject.service(),
		session: Ember.inject.service(),

		// Single line CP
		gmConsoleLink: Ember.computed.alias('gmc.gmConsoleLink')

		// Multiline CP

		// Lifecycle hooks

		// Private

	});
});