define('gmc/routes/response-type/edit/version', ['exports', 'gmc-game-settings/routes/response-type/edit/version'], function (exports, _version) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _version.default;
    }
  });
});