define('gmc/components/layout-h-full', ['exports', 'ui-core/components/layout-h-full'], function (exports, _layoutHFull) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _layoutHFull.default;
    }
  });
});