define('gmc/helpers/dynamic-segment', ['exports', 'gmc-liveops/helpers/dynamic-segment'], function (exports, _dynamicSegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dynamicSegment.default;
    }
  });
  Object.defineProperty(exports, 'dynamicSegment', {
    enumerable: true,
    get: function () {
      return _dynamicSegment.dynamicSegment;
    }
  });
});