define('gmc/components/confirm-dialog', ['exports', 'ui-core/components/confirm-dialog'], function (exports, _confirmDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _confirmDialog.default;
    }
  });
});