define('gmc/services/games/outplay-games', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var outplayGameList = exports.outplayGameList = [{
    code: 'pi',
    name: 'Booty Quest',
    icon: '/assets/img/game-icons/bq.png'
  }, {
    code: 'cb',
    name: 'Crafty Blast',
    icon: '/assets/img/game-icons/cb.png'
  }, {
    code: 'cc',
    name: 'Crafty Candy',
    icon: '/assets/img/game-icons/cc.png'
  }, {
    code: 'gr',
    name: 'Chef Blast',
    icon: '/assets/img/game-icons/gr.png'
  }, {
    code: 'mm',
    name: 'Mystery Match',
    icon: '/assets/img/game-icons/mm.png'
  }, {
    code: 'mv',
    name: 'Mystery Match Village',
    icon: '/assets/img/game-icons/mmv.png'
  }, {
    code: 'fb-sol',
    name: 'Solitaire Blitz',
    icon: '/assets/img/game-icons/fb-sol.png'
  }];

  var outplayGamesSeparateProdList = exports.outplayGamesSeparateProdList = [{
    code: 'as',
    name: 'ACS',
    icon: '/assets/img/game-icons/acs.png'
  }, {
    code: 'mo',
    name: 'MOMA',
    icon: '/assets/img/game-icons/mo.png'
  }];
});