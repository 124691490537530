define('gmc/helpers/formula-type-matches', ['exports', 'gmc-events/helpers/formula-type-matches'], function (exports, _formulaTypeMatches) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formulaTypeMatches.default;
    }
  });
  Object.defineProperty(exports, 'formulaTypeMatches', {
    enumerable: true,
    get: function () {
      return _formulaTypeMatches.formulaTypeMatches;
    }
  });
});