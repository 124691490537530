define('gmc/components/required-game-settings-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // Defaults

    gmc: Ember.inject.service(),
    router: Ember.inject.service(),
    showSelectGame: false,

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    actions: {
      selectGame: function selectGame(game) {
        Ember.get(this, 'gmc').setSession(game);
        Ember.set(this, 'showSelectGame', false);
        Ember.get(this, 'router').transitionTo('index');
      }
    }

    // Private

  });
});