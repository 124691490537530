define('gmc/controllers/create', ['exports', 'gmc-schemas/controllers/create'], function (exports, _create) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _create.default;
    }
  });
});