define('gmc/components/repeat-content', ['exports', 'ui-core/components/repeat-content'], function (exports, _repeatContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _repeatContent.default;
    }
  });
});