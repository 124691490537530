define('gmc/controllers/item-definition', ['exports', 'gmc-inventory-items/controllers/item-definition'], function (exports, _itemDefinition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _itemDefinition.default;
    }
  });
});