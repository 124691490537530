define('gmc/components/layout-content-overflow', ['exports', 'ui-core/components/layout-content-overflow'], function (exports, _layoutContentOverflow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _layoutContentOverflow.default;
    }
  });
});