define('gmc/services/component-view-service', ['exports', 'gmc-game-settings/services/component-view-service'], function (exports, _componentViewService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _componentViewService.default;
    }
  });
});