define('gmc/helpers/increment-date', ['exports', 'ui-core/helpers/increment-date'], function (exports, _incrementDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _incrementDate.default;
    }
  });
  Object.defineProperty(exports, 'incrementDate', {
    enumerable: true,
    get: function () {
      return _incrementDate.incrementDate;
    }
  });
});