define('gmc/helpers/file-type', ['exports', 'ui-core/helpers/file-type'], function (exports, _fileType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fileType.default;
    }
  });
  Object.defineProperty(exports, 'fileType', {
    enumerable: true,
    get: function () {
      return _fileType.fileType;
    }
  });
});