define('gmc/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    // Global transition

    this.transition(this.use('fade'));

    // Sign in (redirect)

    this.transition(this.onInitialRender(), this.toRoute('sign-in'), this.use('toRight'));

    this.transition(this.fromRoute('sign-in'), this.toRoute('dashboard'), this.use('fade', { duration: 100 }));

    this.transition(this.hasClass('form-sections'),

    // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('fade'),

    // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('fade'));

    // D.A.M Asset-view
    this.transition(this.fromRoute('digital-asset-management.index'), this.toRoute('digital-asset-management.index.asset-view'), this.use('toLeft'));
  };
});