define('gmc/components/fullscreen-dialog', ['exports', 'ui-core/components/fullscreen-dialog'], function (exports, _fullscreenDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fullscreenDialog.default;
    }
  });
});