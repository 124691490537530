define('gmc/helpers/show-private-package', ['exports', 'gmc-liveops/helpers/show-private-package'], function (exports, _showPrivatePackage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _showPrivatePackage.default;
    }
  });
  Object.defineProperty(exports, 'showPrivatePackage', {
    enumerable: true,
    get: function () {
      return _showPrivatePackage.showPrivatePackage;
    }
  });
});