define('gmc/controllers/application', ['exports', 'gmc/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    // Defaults

    gmc: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),

    protectDangerousActions: _environment.default.APP.protectDangerousActions,
    protectDangerousActionsName: _environment.default.APP.protectDangerousActionsName,

    badgeBackgroundColor: Ember.computed(function () {
      if (this.protectDangerousActions === true) {
        return "bg-outplay-orange";
      }
      return "bg-outplay-purple-dark";
    }),

    // Single line CP

    // Multiline CP

    // Disable header for certain routes
    noHeader: Ember.computed(function () {
      return ['login.index', 'login.select-game', 'sign-in.index', 'sign-in.select-game', 'register-device', 'loading'];
    }),

    // Disable header for certain routes
    noNav: Ember.computed(function () {
      return ['register-device'];
    }),

    showHeader: Ember.computed('router.currentRouteName', function () {
      if (this.noHeader.includes(this.router.currentRouteName)) {
        return false;
      }
      return true;
    }),

    dashboardBreadcrumbs: Ember.computed('router.currentRouteName', function () {
      if (this.router.currentRouteName === 'dashboard') {
        return true;
      }
      return false;
    }),

    showNav: Ember.computed('router.currentRouteName', function () {
      if (this.noNav.includes(this.router.currentRouteName)) {
        return false;
      }
      return true;
    }),

    // Lifecycle hooks

    actions: {
      selectGame: function selectGame(game) {
        Ember.get(this, 'gmc').setSession(game);
        this.store.unloadAll();
        this.transitionToRoute('index');
      }
    }

    // Private

  });
});