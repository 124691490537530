define('gmc/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    // Defaults

    gmc: Ember.inject.service(),
    session: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Private

    // Lifecycle hooks

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'errorMsg', null);
      Ember.set(this, 'signingIn', null);
    },


    actions: {
      signIn: function signIn() {
        var _this = this;

        Ember.get(this, 'session').authenticate('authenticator:torii', 'azure-oauth2').then(function () {

          // On sign-in, fetch game settings
          Ember.get(_this, 'gmc.fetchSettings').perform();
        }).catch(function (reason) {
          if (reason) {
            Ember.set(_this, 'errorMsg', reason);
          }
          Ember.set(_this, 'signingIn', false);
        });
      }
    }

  });
});