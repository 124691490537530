define('gmc/components/process-cross-promo', ['exports', 'gmc-cross-promos/components/process-cross-promo'], function (exports, _processCrossPromo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _processCrossPromo.default;
    }
  });
});