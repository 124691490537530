define('gmc/mixins/changeset-array', ['exports', 'ui-forms/mixins/changeset-array'], function (exports, _changesetArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _changesetArray.default;
    }
  });
});