define('gmc/validators/event-reward-iap-id-exists', ['exports', 'gmc-events/validators/event-reward-iap-id-exists'], function (exports, _eventRewardIapIdExists) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eventRewardIapIdExists.default;
    }
  });
});