define('gmc/serializers/event-tier', ['exports', 'gmc-events/serializers/event-tier'], function (exports, _eventTier) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eventTier.default;
    }
  });
});