define('gmc/helpers/list-item-label', ['exports', 'ui-core/helpers/list-item-label'], function (exports, _listItemLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listItemLabel.default;
    }
  });
  Object.defineProperty(exports, 'listItemLabel', {
    enumerable: true,
    get: function () {
      return _listItemLabel.listItemLabel;
    }
  });
});