define('gmc/components/last-updated', ['exports', 'ui-core/components/last-updated'], function (exports, _lastUpdated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _lastUpdated.default;
    }
  });
});