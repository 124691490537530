define('gmc/components/loading-indicator', ['exports', 'ui-core/components/loading-indicator'], function (exports, _loadingIndicator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loadingIndicator.default;
    }
  });
});