define('gmc/services/schema-service', ['exports', 'gmc-schemas/services/schema-service'], function (exports, _schemaService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _schemaService.default;
    }
  });
});