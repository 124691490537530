define('gmc/components/switch-game', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // Defaults

    gmc: Ember.inject.service(),
    session: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    actions: {
      selectGame: function selectGame(game) {
        Ember.get(this, 'selectGame')(game);
      }
    }

    // Private

  });
});