define('gmc/services/games/as-prod', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ASProdGameList = exports.ASProdGameList = [{
        code: 'as',
        name: 'AS',
        icon: '/assets/img/game-icons/acs.png'
    }];
});