define('gmc/routes/base', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    // Defaults

    gmc: Ember.inject.service(),

    // Ember Simple Auth config
    authenticationRoute: 'login'

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    // Private

  });
});