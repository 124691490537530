define('gmc/helpers/segment-groups', ['exports', 'gmc-liveops/helpers/segment-groups'], function (exports, _segmentGroups) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _segmentGroups.default;
    }
  });
  Object.defineProperty(exports, 'segmentGroups', {
    enumerable: true,
    get: function () {
      return _segmentGroups.segmentGroups;
    }
  });
});