define('gmc/validators/booty-quest-fields', ['exports', 'gmc-cross-promos/validators/booty-quest-fields'], function (exports, _bootyQuestFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bootyQuestFields.default;
    }
  });
});