define('gmc/tailwind/config/min-width', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    '0': '0',
    'full': '100%'
  };
});