define('gmc/helpers/format-bytes', ['exports', 'ui-core/helpers/format-bytes'], function (exports, _formatBytes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatBytes.default;
    }
  });
  Object.defineProperty(exports, 'formatBytes', {
    enumerable: true,
    get: function () {
      return _formatBytes.formatBytes;
    }
  });
});