define('gmc/initializers/ember-cli-uuid', ['exports', 'ember-data', 'gmc/config/environment', 'ember-cli-uuid/mixins/adapters/uuid', 'ember-cli-uuid/configuration'], function (exports, _emberData, _environment, _uuid, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    name: 'ember-cli-uuid',

    initialize: function initialize() {

      var config = _environment.default['ember-cli-uuid'] || {};
      _configuration.default.load(config);

      _emberData.default.Adapter.reopen({
        generateIdForRecord: function generateIdForRecord() {
          return _configuration.default.defaultUUID ? _uuid.generateIdForRecord.apply(undefined, arguments) : null;
        }
      });
    }
  };
});