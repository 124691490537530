define('gmc/services/code-mirror', ['exports', 'ivy-codemirror/services/code-mirror'], function (exports, _codeMirror) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _codeMirror.default;
    }
  });
});