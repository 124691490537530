define('gmc/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({

    gmc: Ember.inject.service(),

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      // If game attributes, set based on current session
      var gameCode = Ember.get(this, 'gmc.session.game.code');

      if (json.data.hasOwnProperty('attributes')) {
        if (json.data.attributes.hasOwnProperty('game_code')) {
          json.data.attributes.game_code = gameCode;
        }
      }
    }
  });
});