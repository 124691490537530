define('gmc/transforms/utc', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    serialize: function serialize(date) {
      return date;
    },
    deserialize: function deserialize(date) {
      var type = typeof date === 'undefined' ? 'undefined' : _typeof(date);

      if ((0, _moment.default)(date).isValid()) {
        // Not ISO 8601 format
        if (date.indexOf(' ') >= 0) {
          date = _moment.default.utc(date).toISOString();
        }
      }

      return date;
    }
  });
});