define('gmc/services/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({

    // Injections

    flashMessages: Ember.inject.service(),

    // Props

    // State

    // Single line CP

    // Multiline CP

    default: Ember.computed(function () {
      return [{
        status: null,
        detail: 'There is an uknown issue.  Please contact the administrator'
      }];
    }),

    // Lifecycle hooks

    handler: function handler(adapterErrors) {
      var _this = this;

      var flashMsg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var errors = this._formatError(adapterErrors) || this.default;

      if (flashMsg) {
        errors.forEach(function (error) {
          _this.flashMessages.danger(_this._formatFlashMsg(error.detail, flashMsg), { sticky: true });
        });

        return true;
      }

      return errors;
    },


    // Tasks

    // Private

    _formatMsg: function _formatMsg(msg) {
      return msg.replace('Unprocessable Entity: ', '');
    },
    _formatFlashMsg: function _formatFlashMsg(msg, flashMsg) {
      if ((typeof flashMsg === 'undefined' ? 'undefined' : _typeof(flashMsg)) === 'object') {
        if (flashMsg.hasOwnProperty('prefix')) {
          return flashMsg.prefix + ' ' + msg;
        } else if (flashMsg.hasOwnProperty('affix')) {
          return msg + ' ' + flashMsg.affix;
        }
      }

      return msg.capitalize();
    },
    _formatError: function _formatError(adapterErrors) {
      var _this2 = this;

      var formattedErrors = [];

      if (typeof adapterErrors === 'string' && adapterErrors.length) {
        formattedErrors.push({
          status: null,
          detail: adapterErrors
        });

        return formattedErrors;
      }

      if (adapterErrors.hasOwnProperty('errors')) {
        if (Ember.isArray(adapterErrors.errors)) {
          adapterErrors.errors.forEach(function (error) {
            if (error.hasOwnProperty('status') && error.hasOwnProperty('detail')) {
              formattedErrors.push({
                status: error.status,
                detail: _this2._formatMsg(error.detail)
              });
            }
          });
          if (formattedErrors.length) {
            return formattedErrors;
          }
          return false;
        }
        return false;
      }
      return false;
    }
  });
});