define('gmc/services/client-setting-service', ['exports', 'gmc-game-settings/services/client-setting-service'], function (exports, _clientSettingService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clientSettingService.default;
    }
  });
});