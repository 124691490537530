define('gmc/components/footer-toggle', ['exports', 'ui-core/components/footer-toggle'], function (exports, _footerToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _footerToggle.default;
    }
  });
});