define('gmc/components/game-settings-form', ['exports', 'gmc-game-settings/components/game-settings-form'], function (exports, _gameSettingsForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gameSettingsForm.default;
    }
  });
});