define('gmc/tailwind/config/background-size', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'auto': 'auto',
    'cover': 'cover',
    'contain': 'contain'
  };
});