define('gmc/adapters/cross-promo', ['exports', 'gmc-cross-promos/adapters/cross-promo'], function (exports, _crossPromo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _crossPromo.default;
    }
  });
});