define('gmc/components/if-engine', ['exports', 'ui-core/components/if-engine'], function (exports, _ifEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ifEngine.default;
    }
  });
});