define('gmc/helpers/filename-from-url', ['exports', 'gmc-cross-promos/helpers/filename-from-url'], function (exports, _filenameFromUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _filenameFromUrl.default;
    }
  });
  Object.defineProperty(exports, 'filenameFromUrl', {
    enumerable: true,
    get: function () {
      return _filenameFromUrl.filenameFromUrl;
    }
  });
});