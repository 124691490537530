define('gmc/helpers/template-name', ['exports', 'gmc-notifications/helpers/template-name'], function (exports, _templateName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _templateName.default;
    }
  });
  Object.defineProperty(exports, 'templateName', {
    enumerable: true,
    get: function () {
      return _templateName.templateName;
    }
  });
});