define('gmc/models/store-item', ['exports', 'gmc-store-items/models/store-item'], function (exports, _storeItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _storeItem.default;
    }
  });
});