define('gmc/app', ['exports', 'gmc/resolver', 'ember-load-initializers', 'gmc/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,

    LOG_TRANSITIONS: false,
    // Extremely detailed logging, highlighting every internal
    // step made while transitioning into a route, including
    // `beforeModel`, `model`, and `afterModel` hooks, and
    // information about redirects and aborted transitions
    LOG_TRANSITIONS_INTERNAL: false,

    engines: {

      gmcEvents: {
        dependencies: {
          services: ['ajax', 'error', 'record', 'engineService', 'gameSettings', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData', 'media', 's3Signer'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcGameSettings: {
        dependencies: {
          services: ['ajax', 'error', 'gmc', 'record', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcGifts: {
        dependencies: {
          services: ['ajax', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcLiveops: {
        dependencies: {
          services: ['ajax', 'engineService', 'gmc', 'gceSession', 'session', 'store', 'record', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index',
            'event-detail': 'events.by-type',
            'ota-edit': 'ota.edit',
            'notification-detail': 'notifications.detail'
          }
        }
      },

      gmcLeaderboards: {
        dependencies: {
          services: ['ajax', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcNotifications: {
        dependencies: {
          services: ['ajax', 'gameSettings', 'error', 'record', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcOtaContent: {
        dependencies: {
          services: ['ajax', 'engineService', 'error', 'gmc', 'gceSession', 'session', 'store', 'record', 'router', 'flashMessages', 'headData', 's3Signer'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcSchemas: {
        dependencies: {
          services: ['ajax', 'engineService', 'error', 'gmc', 'gceSession', 'session', 'store', 'router', 'record', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcStoreItems: {
        dependencies: {
          services: ['ajax', 'error', 'flashMessages', 'gameSettings', 'gmc', 'gceSession', 'headData', 'router', 'record', 'session', 'store'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },

      gmcTournaments: {
        dependencies: {
          services: ['ajax', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData', 'gameSettings'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },
      gmcCrossPromos: {
        dependencies: {
          services: ['ajax', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData', 'gameSettings'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },
      gmcDigitalAssetManagement: {
        dependencies: {
          services: ['ajax', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'sign-in',
            'main-dashboard': 'dashboard'
          }
        }
      },
      gmcPlayerState: {
        dependencies: {
          services: ['ajax', 'gameSettings', 'error', 'record', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },
      gmcInventoryItems: {
        dependencies: {
          services: ['ajax', 'gameSettings', 'error', 'record', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      },
      gmcPlayerInventory: {
        dependencies: {
          services: ['ajax', 'gameSettings', 'error', 'record', 'gmc', 'gceSession', 'session', 'store', 'router', 'flashMessages', 'headData'],
          externalRoutes: {
            'main-login': 'login',
            'main-dashboard': 'index'
          }
        }
      }

    }

  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});