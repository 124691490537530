define('gmc/routes/not-found', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    // Defaults

    router: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      // Redirect to /not-found
      var url = Ember.get(this, 'router').location.formatURL('/not-found');
      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    }

    // Private

  });
});