define('gmc/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'moment'], function (exports, _applicationRouteMixin, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

    // Defaults

    session: Ember.inject.service(),

    classNames: ['bg-grey-lighter', 'font-sans', 'antialiased'],

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      if (Ember.get(this, 'session.isAuthenticated')) {
        Ember.run.once(function () {
          if (_this._tokenHasExpired()) {
            console.info('token expired');

            Ember.get(_this, 'session').invalidate();
          }
        });
      }
    },


    // Private

    // TODO tokenHasExpired in gceSession service causes the GMC to timeout
    // workaround to include the methods here

    _tokenHasExpired: function _tokenHasExpired() {
      if (Ember.get(this, 'session.data.authenticated.access_token')) {
        var token = this._parseToken(Ember.get(this, 'session.data.authenticated.access_token'));

        var expireDateTime = _moment.default.unix(token.exp).subtract(30, 'minutes');

        if (expireDateTime.isBefore((0, _moment.default)())) {
          return true;
        }
        return false;
      }
    },
    _parseToken: function _parseToken(token) {
      var _token$split = token.split('.'),
          _token$split2 = _slicedToArray(_token$split, 2),
          base64Url = _token$split2[1];

      if (base64Url) {
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
      return false;
    }
  });
});