define('gmc/components/ui-tab-section', ['exports', 'ui-core/components/ui-tab-section'], function (exports, _uiTabSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uiTabSection.default;
    }
  });
});