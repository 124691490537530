define('gmc/validators/conditional-presence', ['exports', 'gmc-cross-promos/validators/conditional-presence'], function (exports, _conditionalPresence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conditionalPresence.default;
    }
  });
});