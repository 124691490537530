define('gmc/routes/index', ['exports', 'gmc/routes/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    // Defaults
    gmc: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Lifecycle hooks
    beforeModel: function beforeModel() {
      if (this.gmc.close) {
        window.close();
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.transitionTo('dashboard', Ember.get(this, 'gmc.session.game.code'));
    }

    // Private

  });
});