define('gmc/components/sidebar-dates', ['exports', 'ui-core/components/sidebar-dates'], function (exports, _sidebarDates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sidebarDates.default;
    }
  });
});