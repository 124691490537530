define('gmc/components/ui-tabs', ['exports', 'ui-core/components/ui-tabs'], function (exports, _uiTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uiTabs.default;
    }
  });
});