define('gmc/components/processing-modal', ['exports', 'ui-core/components/processing-modal'], function (exports, _processingModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _processingModal.default;
    }
  });
});