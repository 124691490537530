define('gmc/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    // See routes/sign-in/index

    // Defaults

    // Single line CP


    // Multiline CP

    // Lifecycle hooks

    // Private

  });
});