define('gmc/helpers/components/private-packages', ['exports', 'gmc-liveops/components/private-packages'], function (exports, _privatePackages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _privatePackages.default;
    }
  });
});