define('gmc/services/gce-session', ['exports', 'ui-auth/services/gce-session'], function (exports, _gceSession) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gceSession.default;
    }
  });
});