define('gmc/components/s3-asset-upload', ['exports', 'ui-core/components/s3-asset-upload'], function (exports, _s3AssetUpload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _s3AssetUpload.default;
    }
  });
});