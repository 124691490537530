define('gmc/helpers/components/update-status', ['exports', 'gmc-liveops/components/update-status'], function (exports, _updateStatus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _updateStatus.default;
    }
  });
});