define('gmc/index', ['exports', 'ember-cli-uuid'], function (exports, _emberCliUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'uuid', {
    enumerable: true,
    get: function () {
      return _emberCliUuid.uuid;
    }
  });
});