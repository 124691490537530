define('gmc/validators/leaderboard-score-types', ['exports', 'gmc-leaderboards/validators/leaderboard-score-types'], function (exports, _leaderboardScoreTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _leaderboardScoreTypes.default;
    }
  });
});