define('gmc/models/assignment', ['exports', 'gmc-gifts/models/assignment'], function (exports, _assignment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _assignment.default;
    }
  });
});