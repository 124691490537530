define('gmc/helpers/link-to-show', ['exports', 'ui-core/helpers/link-to-show'], function (exports, _linkToShow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkToShow.default;
    }
  });
  Object.defineProperty(exports, 'alphaChar', {
    enumerable: true,
    get: function () {
      return _linkToShow.alphaChar;
    }
  });
});