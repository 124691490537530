define('gmc/adapters/application', ['exports', 'ember-data', 'gmc/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  exports.default = JSONAPIAdapter.extend({

    session: Ember.inject.service(),
    gmc: Ember.inject.service(),
    host: _environment.default.host,
    headers: Ember.computed('session.data.authenticated.access_token', 'gmc.session.game.code', function () {
      return {
        'Authorization': 'Bearer ' + Ember.get(this, 'session.data.authenticated.access_token'),
        'X-Game-Code': Ember.get(this, 'gmc.session.game.code'),
        'Accept': 'application/vnd.api+json'
      };
    })

  });
});