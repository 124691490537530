define('gmc/helpers/get-label', ['exports', 'ui-core/helpers/get-label'], function (exports, _getLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getLabel.default;
    }
  });
  Object.defineProperty(exports, 'getLabel', {
    enumerable: true,
    get: function () {
      return _getLabel.getLabel;
    }
  });
});