define('gmc/services/tournament-service', ['exports', 'gmc-tournaments/services/tournament-service'], function (exports, _tournamentService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tournamentService.default;
    }
  });
});