define('gmc/helpers/formula-type', ['exports', 'gmc-events/helpers/formula-type'], function (exports, _formulaType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formulaType.default;
    }
  });
  Object.defineProperty(exports, 'formulaType', {
    enumerable: true,
    get: function () {
      return _formulaType.formulaType;
    }
  });
});