define('gmc/components/form-section', ['exports', 'gmc-inventory-items/components/form-section'], function (exports, _formSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formSection.default;
    }
  });
});