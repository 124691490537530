define('gmc/components/game-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // Defaults

    gmc: Ember.inject.service(),
    showIcon: true,
    disabled: false,

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    actions: {
      selectGame: function selectGame(game) {
        if (!Ember.get(this, 'disabled')) {
          Ember.get(this, 'selectGame')(game);
        }
      }
    }

    // Private

  });
});