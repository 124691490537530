define('gmc/models/inventory-instance', ['exports', 'gmc-player-inventory/models/inventory-instance'], function (exports, _inventoryInstance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inventoryInstance.default;
    }
  });
});