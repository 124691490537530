define('gmc/services/session-storage', ['exports', 'gmc/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    affix: Ember.computed(_environment.default.APP.gmcEnv, function () {
      var host = 'outplay-' + _environment.default.APP.gmcEnv;

      if (host === 'outplay-prd') {
        host = 'outplay';
      }

      return 'session:gmc.' + host + '.com';
    }),

    get: function get(sessionName) {
      return localStorage.getItem(this._getSessionName(sessionName)) || false;
    },
    save: function save(sessionName, payload) {
      sessionName = this._getSessionName(sessionName);

      localStorage.setItem(sessionName, payload);
      return localStorage.getItem(sessionName) || false;
    },
    remove: function remove(sessionName) {
      localStorage.setItem(this._getSessionName(sessionName), '');
    },
    _getSessionName: function _getSessionName(sessionName) {
      return sessionName + ':' + Ember.get(this, 'affix');
    }
  });
});