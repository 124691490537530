define('gmc/controllers/login/select-game', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    // Defaults

    gmc: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    init: function init() {
      Ember.set(this, 'gmc.settingGame', false);
    },


    actions: {
      selectGame: function selectGame(game) {
        Ember.get(this, 'gmc').setSession(game);
        Ember.set(this, 'gmc.settingGame', false);
        this.transitionToRoute('login');
      }
    }

    // Private

  });
});