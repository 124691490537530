define('gmc/helpers/validation-message', ['exports', 'ui-forms/helpers/validation-message'], function (exports, _validationMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _validationMessage.default;
    }
  });
  Object.defineProperty(exports, 'validationMessage', {
    enumerable: true,
    get: function () {
      return _validationMessage.validationMessage;
    }
  });
});