define('gmc/controllers/register-device', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    // Injections

    gmc: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    // Props

    device: undefined,

    // State

    href: Ember.computed('gmc.session.game.code', function () {
      return 'https://deeplink.outplay.com/' + this.gmc.session.game.code + '/gmc-register';
    }),

    // Single line CP

    // Multiline CP

    accessToken: Ember.computed('session.data.authenticated.access_token', function () {
      return this.session.data.authenticated.access_token;
    }),

    deepLink: Ember.computed('session', function () {
      return this.session.isAuthenticated ? this.href + '?jwt=' + this.accessToken : false;
    }),

    // Lifecycle hooks

    actions: {
      registerDevice: function registerDevice() {
        var deepLink = this.deepLink;

        if (!deepLink) {
          this.flashMessages.warning('You are not signed-in');
          this.session.invalidate();
          return false;
        }

        if (this.deviceName) {
          deepLink = this.deepLink + '&device-name=' + this.deviceName;
        }

        window.location.replace(deepLink);
      }
    }

    // Tasks

    // Private

  });
});