define('gmc/components/layout-sidebar-content', ['exports', 'ui-core/components/layout-sidebar-content'], function (exports, _layoutSidebarContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _layoutSidebarContent.default;
    }
  });
});