define('gmc/helpers/object-item', ['exports', 'ui-forms/helpers/object-item'], function (exports, _objectItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _objectItem.default;
    }
  });
  Object.defineProperty(exports, 'objectItem', {
    enumerable: true,
    get: function () {
      return _objectItem.objectItem;
    }
  });
});