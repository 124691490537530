define('gmc/components/bread-crumbs-item', ['exports', 'ui-breadcrumbs/components/bread-crumbs-item'], function (exports, _breadCrumbsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _breadCrumbsItem.default;
    }
  });
});