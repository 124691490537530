define('gmc/components/settings-meta', ['exports', 'gmc-game-settings/components/settings-meta'], function (exports, _settingsMeta) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _settingsMeta.default;
    }
  });
});