define('gmc/tailwind/config/screens', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'sm': '576px',
    'md': '768px',
    'lg': '992px',
    'xl': '1200px',

    // Custom
    'xxl': '1300px'
  };
});