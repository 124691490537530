define('gmc/validators/tournament-score-types', ['exports', 'gmc-tournaments/validators/tournament-score-types'], function (exports, _tournamentScoreTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tournamentScoreTypes.default;
    }
  });
});