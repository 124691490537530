define('gmc/helpers/status', ['exports', 'ui-core/helpers/status'], function (exports, _status) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _status.default;
    }
  });
  Object.defineProperty(exports, 'status', {
    enumerable: true,
    get: function () {
      return _status.status;
    }
  });
});