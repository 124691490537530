define('gmc/services/progression-event-service', ['exports', 'gmc-events/services/progression-service'], function (exports, _progressionService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _progressionService.default;
    }
  });
});