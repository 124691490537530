define('gmc/controllers/detail/localisation', ['exports', 'gmc-events/controllers/detail/localisation'], function (exports, _localisation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _localisation.default;
    }
  });
});