define('gmc/models/event-asset', ['exports', 'gmc-events/models/event-asset'], function (exports, _eventAsset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eventAsset.default;
    }
  });
});