define('gmc/components/settings-panel-icon-external', ['exports', 'gmc-game-settings/components/settings-panel-icon-external'], function (exports, _settingsPanelIconExternal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _settingsPanelIconExternal.default;
    }
  });
});