define('gmc/torii-providers/azure-oauth2', ['exports', 'ui-auth/torii-providers/azure-oauth2'], function (exports, _azureOauth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _azureOauth.default;
    }
  });
});