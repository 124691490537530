define('gmc/adapters/tournament', ['exports', 'gmc-tournaments/adpaters/tournament'], function (exports, _tournament) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tournament.default;
    }
  });
});