define('gmc/components/show-status', ['exports', 'gmc-cross-promos/components/show-status'], function (exports, _showStatus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _showStatus.default;
    }
  });
});