define('gmc/routes/response-type/update', ['exports', 'gmc-game-settings/routes/response-type/update'], function (exports, _update) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _update.default;
    }
  });
});