define('gmc/routes/register-device', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    // Injections

    // Props

    // State

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    // Tasks

    // Private

  });
});