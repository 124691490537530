define('gmc/tailwind/config/height', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'auto': 'auto',
    'px': '1px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '24': '6rem',
    '32': '8rem',
    '48': '12rem',
    '64': '16rem',
    'full': '100%',
    'screen': '100vh',

    // Custom

    '1/2': '50%',
    '100px': '100px',
    '150px': '150px',
    '200px': '200px',
    '250px': '250px',
    '300px': '300px',
    '350px': '350px',
    '400px': '400px',
    '450px': '450px',
    '500px': '500px'
  };
});