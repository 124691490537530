define('gmc/controllers/client-setting/edit', ['exports', 'gmc-game-settings/controllers/client-setting/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _edit.default;
    }
  });
});