define('gmc/components/store-item-context-menu', ['exports', 'gmc-store-items/components/store-item-context-menu'], function (exports, _storeItemContextMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _storeItemContextMenu.default;
    }
  });
});