define('gmc/helpers/pagination-label', ['exports', 'ui-core/helpers/pagination-label'], function (exports, _paginationLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paginationLabel.default;
    }
  });
  Object.defineProperty(exports, 'paginationLabel', {
    enumerable: true,
    get: function () {
      return _paginationLabel.paginationLabel;
    }
  });
});