define('gmc/router', ['exports', 'gmc/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    // Dashboard

    this.route('dashboard', { path: ':game_code/dashboard' });

    // 404

    this.route('not-found', { path: '/*path' });

    // Auth

    this.route('login', { path: '/sign-in' }, function () {
      this.route('select-game');
    });

    // Register Device

    this.route('register-device');

    // Engines

    this.mount('gmc-events', { as: 'events', path: ':game_code/events' });
    this.mount('gmc-game-settings', { as: 'game-settings', path: ':game_code/admin/games' });
    this.mount('gmc-gifts', { as: 'gifts', path: ':game_code/gifts' });
    this.mount('gmc-leaderboards', { as: 'leaderboards', path: ':game_code/leaderboards' });
    this.mount('gmc-liveops', { as: 'live-ops', path: '/:game_code/live-ops' });
    this.mount('gmc-notifications', { as: 'notifications', path: ':game_code/notifications' });
    this.mount('gmc-ota-content', { as: 'ota', path: ':game_code/ota' });
    this.mount('gmc-schemas', { as: 'schemas', path: ':game_code/schemas' });
    this.mount('gmc-store-items', { as: 'store-items', path: '/:game_code/store-items' });
    this.mount('gmc-tournaments', { as: 'tournaments', path: '/:game_code/tournaments' });
    this.mount('gmc-cross-promos', { as: 'cross-promos', path: '/:game_code/cross-promos' });
    this.mount('gmc-player-state', { as: 'player-state', path: '/:game_code/player-state' });
    this.mount('gmc-inventory-items', { as: 'inventory-items', path: '/:game_code/inventory-items' });
    this.mount('gmc-player-inventory', { as: 'player-inventory', path: '/:game_code/player-inventory' });
  });

  exports.default = Router;
});