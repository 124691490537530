define('gmc/routes/login/select-game', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    // Defaults

    gceSession: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      // If already signed in, redirect
      if (Ember.get(this, 'gceSession.token')) {
        this.transitionTo('index');
      }
    }

    // Private

  });
});