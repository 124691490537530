define('gmc/services/private-package', ['exports', 'gmc-shared/services/private-package'], function (exports, _privatePackage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _privatePackage.default;
    }
  });
});