define('gmc/helpers/alpha-char', ['exports', 'ui-core/helpers/alpha-char'], function (exports, _alphaChar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _alphaChar.default;
    }
  });
  Object.defineProperty(exports, 'alphaChar', {
    enumerable: true,
    get: function () {
      return _alphaChar.alphaChar;
    }
  });
});