define('gmc/serializers/leaderboard-rank', ['exports', 'gmc-leaderboards/serializers/leaderboard-rank'], function (exports, _leaderboardRank) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _leaderboardRank.default;
    }
  });
});