define('gmc/components/updated-by', ['exports', 'ui-core/components/updated-by'], function (exports, _updatedBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _updatedBy.default;
    }
  });
});