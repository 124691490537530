define('gmc/components/tournament-context-menu', ['exports', 'gmc-tournaments/components/tournament-context-menu'], function (exports, _tournamentContextMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tournamentContextMenu.default;
    }
  });
});