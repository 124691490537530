define('gmc/services/games/sybo-games', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var syboGameList = exports.syboGameList = [{
        code: 'sb',
        name: 'SSB',
        icon: '/assets/img/game-icons/ssb.png'
    }];
});