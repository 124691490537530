define('gmc/components/notification-preview', ['exports', 'gmc-notifications/components/notification-preview'], function (exports, _notificationPreview) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _notificationPreview.default;
    }
  });
});