define('gmc/models/cross-promo-publish', ['exports', 'gmc-cross-promos/models/cross-promo-publish'], function (exports, _crossPromoPublish) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _crossPromoPublish.default;
    }
  });
});