define('gmc/mixins/game-code', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    gmc: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var gameCode = void 0;
      var baseRouteParams = transition.params[Object.keys(transition.params)[1]];
      if (baseRouteParams) {
        gameCode = baseRouteParams['game_code'];
      }

      if (!gameCode) {
        this.transitionTo('index');
      }

      this.gmc.setSession(this.gmc.global.games.find(function (g) {
        return g.code === gameCode;
      }));
    }
  });
});