define('gmc/serializers/gift', ['exports', 'gmc-gifts/serializers/gift'], function (exports, _gift) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gift.default;
    }
  });
});