define('gmc/controllers/index/create-event', ['exports', 'gmc-events/controllers/index/create-event'], function (exports, _createEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _createEvent.default;
    }
  });
});